<template>
  <v-container v-if="loading" class="d-flex flex-column">
    <view-loader />
  </v-container>
  <v-container v-else class="d-flex flex-column pa-8">
    <template>
      <h2 class="view-title">Keyword Report</h2>

      <div class="header-row">
        <div class="header-report">
          <div class="row-text">
            <span>Report Name: {{ report.report_name }} </span>
          </div>
          <div class="row-text">
            <span>Report Type: {{ report.type === 'paid' ? 'Paid' : '' }} </span>
            <span>Report Type: {{ report.type === 'organic' ? 'Organic' : '' }} </span>
            <span>Report Type: {{ report.type === 'ranked_keywords' ? 'Ranked Keywords' : '' }} </span>
          </div>
          <div class="row-text">
            <span
              >Client URL:
              <a :href="report.client_url">{{ report.client_url }}</a>
            </span>
          </div>
          <div class="row-text">
            <div class="col-comparison">
              <span v-for="(url, i) in report.competitor_urls" :key="i">
                Comparison URL: {{ i + 1 }} <a :href="url">{{ url }}</a>
              </span>
              <br />
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="!loading && all">
      <table-report-paid :loading="loading" :data="all" :report="report" v-if="report.type == 1" :shared="true" />
      <table-report-organic :loading="loading" :data="all" :report="report" v-if="report.type == 0" :shared="true" />
    </template>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import TableReportPaid from '@/views/pages/keyword-research/components/TableReportPaid.vue'
import ViewLoader from '@/components/spinner/ViewLoader.vue'
import TableReportOrganic from '@/views/pages/keyword-research/components/TableReportOrganic.vue'
import DialogShareReport from '@/components/dialog/DialogShareReport.vue'

export default {
  name: 'share-keyword-research',
  components: {
    TableReportPaid,
    ViewLoader,
    TableReportOrganic,
    DialogShareReport,
  },
  props: {
    token: String,
    data: Object,
  },
  data() {
    return {
      loading: false,
      report: [],
      search: '',
      tab: null,
      all: [],
      starred: [],
    }
  },
  created() {
    this.report = this.data.keywordResearch
    this.all = this.data.keywords
  },
  methods: {
    newReport() {
      const path = '/kw-research/new'
      this.$router.push({ path })
    },
  },
}
</script>

<style scoped>
.header-report {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.header-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.col-comparison {
  display: flex;
  flex-direction: column;
}
.header-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.view-title {
  margin-bottom: 20px;
}
.search {
  max-width: 350px;
}
.cont-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.col-actions {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>

