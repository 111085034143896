<template>
  <v-container v-if="loading" class="d-flex flex-column">
    <view-loader />
  </v-container>
  <v-container v-else class="d-flex flex-column">
    <template>
      <h2>Semantic Analysis Crawl Results</h2>

      <div class="header-row">
        <div class="header-report">
          <div class="row-text">
            <span>Report Name: {{ report.report_name }} </span>
          </div>
          <div class="row-text">
            <span
              >Keyword:
              <a :href="googleLink(serp_data.keyword)">{{ serp_data.keyword }}</a>
            </span>
          </div>
          <div class="row-text">
            <div class="col-comparison">
              <span v-if="!!serp_data.comp_urls"
                >Comparison URL:
                <a v-for="comp_url in serp_data.comp_urls" :Key="comp_url" :href="serp_data.comp_url" target="_blank">{{
                  serp_data.comp_url
                }}</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>

    <all-components v-if="data" :loading="loading" :values="data" />
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import ViewLoader from '@/components/spinner/ViewLoader.vue'
import AllComponents from '@/views/pages/semantic-analysis/components/SemanticAllComponents.vue'
import GoogleSelectDialog from '@/views/pages/semantic-analysis/components/GoogleSelectDialog.vue'
import DialogExport from '@/views/pages/semantic-analysis/components/DialogExport.vue'
import DialogShareReport from '@/components/dialog/DialogShareReport.vue'

export default {
  name: 'share-semantic-report',
  components: {
    AllComponents,
    ViewLoader,
    GoogleSelectDialog,
    DialogExport,
    DialogShareReport,
  },
  props: {
    token: String,
    data: Object,
  },
  data() {
    return {
      loading: false,
      report: {},
      google_document_id: null,
      serp_data: {},
      hTags: [],
    }
  },
  created() {
    this.report = this.data.tfidfReport
    this.google_document_id = this.data.tfidfReport.google_document_id
    this.serp_data = this.data.tfidfReport.crawllog.serp_data
    this.setDataTags(this.data.reportHeaders)
  },
  methods: {
    googleLink(word) {
      return `https://www.google.com/search?q=${word}`
    },
    toGoogleLink(word) {
      window.open(`https://www.google.com/search?q=${word}`, '_blank')
    },
    setDataTags(headers) {
      let h = [
        { data: [], tag: 'H1' },
        { data: [], tag: 'H2' },
        { data: [], tag: 'H3' },
        { data: [], tag: 'H4' },
        { data: [], tag: 'H5' },
        { data: [], tag: 'H6' },
      ]
      headers.forEach((e, i) => {
        if (e.rank >= 100) {
          if (e.h1) h[0].data.unshift({ rank: 'Client', text: e.h1 })
          if (e.h2) h[1].data.unshift({ rank: 'Client', text: e.h2 })
          if (e.h3) h[2].data.unshift({ rank: 'Client', text: e.h3 })
          if (e.h4) h[3].data.unshift({ rank: 'Client', text: e.h4 })
          if (e.h5) h[4].data.unshift({ rank: 'Client', text: e.h5 })
          if (e.h6) h[5].data.unshift({ rank: 'Client', text: e.h6 })
        } else {
          if (e.h1) h[0].data.push({ rank: e.rank, text: e.h1 })
          if (e.h2) h[1].data.push({ rank: e.rank, text: e.h2 })
          if (e.h3) h[2].data.push({ rank: e.rank, text: e.h3 })
          if (e.h4) h[3].data.push({ rank: e.rank, text: e.h4 })
          if (e.h5) h[4].data.push({ rank: e.rank, text: e.h5 })
          if (e.h6) h[5].data.push({ rank: e.rank, text: e.h6 })
        }
      })
      this.hTags = h
    },
    viewGoogleReport() {
      const url = `https://docs.google.com/document/d/${this.google_document_id}`
      window.open(url, '_blank')
    },
  },
}
</script>

<style scoped>
.header-report {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.header-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.col-comparison {
  display: flex;
  flex-direction: column;
}
.header-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.view-title {
  margin-bottom: 20px;
}
.search {
  max-width: 350px;
}
.cont-google-report {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;
}
.col-actions {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
