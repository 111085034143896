<template>
  <v-container v-if="loading" class="d-flex flex-column">
    <view-loader />
  </v-container>
  <v-container v-else class="d-flex flex-column pa-8">
    <template>
      <h1>Full Report</h1>

      <div class="header-row">
        <div class="header-report">
          <div class="row-text">
            <span> Report Name: {{ report.report_name }} </span>
          </div>
          <div class="row-text">
            <span
              >Keyword: <a :href="googleLink(report.keyword)" target="_blank">{{ report.keyword }}</a>
            </span>
          </div>
          <div class="row-text">
            <div class="col-comparison">
              <span v-for="(url, index) in report.comp_urls" :key="index"
                >Comparison URL: {{ index == 0 ? '' : index + 1 }} <a :href="url" target="_blank">{{ url }}</a>
              </span>
            </div>
          </div>
        </div>

      </div>
    </template>

    <serp-all-components v-if="serp_data" :loading="loading" :data="serp_data" />

    <semantic-all-components v-if="semantic_data" :loading="loading" :values="semantic_data" />
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import SemanticAllComponents from '@/views/pages/semantic-analysis/components/SemanticAllComponents.vue'
import SerpAllComponents from '@/views/pages/serp-analysis/components/SerpAllComponents.vue'
import ViewLoader from '@/components/spinner/ViewLoader.vue'
import DialogExport from '@/views/pages/full-reports/components/DialogExport.vue'
import DialogShareReport from '@/components/dialog/DialogShareReport.vue';

export default {
  name: 'share-full-report',
  components: {
    SerpAllComponents,
    SemanticAllComponents,
    ViewLoader,
    DialogExport,
    DialogShareReport,
  },
  props: {
    token: String,
    data: Object,
    report: Object,
  },
  data() {
    return {
      loading: false,
      serp_data: null,
      semantic_data: null,
    }
  },
  created() {
    this.serp_data = this.data.serp_data;
    this.semantic_data = this.data.semantic_data;
  },
  methods: {
    googleLink(word) {
      return `https://www.google.com/search?q=${word}`
    },
  },
}
</script>

<style scoped>
.header-report {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.header-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.col-comparison {
  display: flex;
  flex-direction: column;
}
.header-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.view-title {
  margin-bottom: 20px;
}
.search {
  max-width: 350px;
}
.col-actions{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
