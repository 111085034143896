<template>
  <v-container v-if="loading" class="d-flex flex-column">
    <view-loader />
  </v-container>
  <v-container v-else class="d-flex flex-column pa-8">
    <template>
      <h2>SERP Crawl Results</h2>
      <div class="header-row" style="margin-top: 10px">
        <div class="header-report">
          <div class="row-text">
            <span> Report Name: {{ report.report_name }} </span>
          </div>
          <div class="row-text">
            <span
              >Keyword: <a :href="googleLink(report.keyword)" target="_blank">{{ report.keyword }}</a>
            </span>
          </div>
          <div class="row-text">
            <div class="col-comparison">
              <span v-for="(url, index) in report.comp_urls" :key="index"
                >Comparison URL: {{ index == 0 ? '' : index + 1 }} <a :href="url" target="_blank">{{ url }}</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>

    <all-components :loading="loading" :data="data.report" />
  </v-container>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import AllComponents from '@/views/pages/serp-analysis/components/SerpAllComponents.vue'
import ViewLoader from '@/components/spinner/ViewLoader.vue'
import DialogExport from '@/views/pages/serp-analysis/components/DialogExport.vue'
import DialogShareReport from '@/components/dialog/DialogShareReport.vue'

export default {
  name: 'share-serp-report',
  components: {
    AllComponents,
    ViewLoader,
    DialogExport,
    DialogShareReport,
  },
  props: {
    token: String,
    data: Object,
    report: Object,
  },
  data() {
    return {
      loading: false,
      export_list: [
        { label: 'Page Metrics Excel', type: 'pagemetrics', format: 'excel' },
        { label: 'Page Metrics CSV', type: 'pagemetrics', format: 'csv' },

        { label: 'Domain link profile Excel', type: 'domainlinkprofile', format: 'excel' },
        { label: 'Domain link profile CSV', type: 'domainlinkprofile', format: 'csv' },

        { label: 'URL link profile Excel', type: 'urllinkprofile', format: 'excel' },
        { label: 'URL link profile CSV', type: 'urllinkprofile', format: 'csv' },

        { label: 'Backlinks Seen Excel', type: 'backlinksseen', format: 'excel' },
        { label: 'Backlinks Seen CSV', type: 'backlinksseen', format: 'csv' },

        { label: 'Domain Level Data Excel', type: 'domainleveldata', format: 'excel' },
        { label: 'Domain Level Data CSV', type: 'domainleveldata', format: 'csv' },

        { label: 'Page Level Data Excel', type: 'pageleveldata', format: 'excel' },
        { label: 'Page Level Data CSV', type: 'pageleveldata', format: 'csv' },

        { label: 'Link Gaps Analysis Excel', type: 'linkgapsanalysis', format: 'excel' },
        { label: 'Link Gaps Analysis CSV', type: 'linkgapsanalysis', format: 'csv' },

        { label: 'Anchors Aggregate Text Excel', type: 'anchorsaggregatetext', format: 'excel' },
        { label: 'Anchors Aggregate Text CSV', type: 'anchorsaggregatetext', format: 'csv' },

        { label: 'Speed and Core Web Vitals Comparison Excel', type: 'speedwebvitals', format: 'excel' },
        { label: 'Speed and Core Web Vitals Comparison CSV', type: 'speedwebvitals', format: 'csv' },
      ],
    }
  },
  created() {},
  methods: {
    googleLink(word) {
      return `https://www.google.com/search?q=${word}`
    },
    downloadReport($report_id, $format, $type) {
      const url = `${process.env.VUE_APP_SERVER_URL}/export/serpanalysis/${$type}/${$report_id}/${$format}`
      window.open(url, '_blank')
    },
  },
}
</script>

<style scoped>
.header-report {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.header-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.col-comparison {
  display: flex;
  flex-direction: column;
}
.header-top {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.view-title {
  margin-bottom: 20px;
}
.search {
  max-width: 350px;
}
.col-actions {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (max-width: 450px) {
  .header-row {
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
}
</style>
