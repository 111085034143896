<template>
  <div class="center">
    <div v-if="error" class="not-found">
      <v-icon color="error" size="75"> mdi-close-circle-outline </v-icon>
      <h2 class="text-center">No report found.</h2>
      <p class="text-center">It appears that the report does not exist or is no longer available.</p>
    </div>

    <div class="report" v-else>
      <v-container v-if="loading" class="d-flex flex-column">
        <view-loader />
      </v-container>

      <share-serp-report
        v-if="loading === false && data.report_type === 'serp_analysis'"
        :data="data"
        :report="data.report.report"
        :token="token"
      />
      <share-semantic-report
        v-else-if="loading === false && data.report_type === 'semantic_report'"
        :data="data.report"
        :report="data.report.report"
        :token="token"
      />
      <share-keyword-research
        v-else-if="loading === false && data.report_type === 'keyword_research'"
        :data="data.report"
        :report="data.report.report"
        :token="token"
      />
      <share-full-report
        v-else-if="loading === false && data.report_type === 'full_report'"
        :data="data.report"
        :report="data.report.report"
        :token="token"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ViewLoader from '@/components/spinner/ViewLoader.vue'
import ShareSerpReport from './components/ShareSerpReport.vue'
import ShareSemanticReport from './components/ShareSemanticReport.vue'
import ShareFullReport from './components/ShareFullReport.vue'
import ShareKeywordResearch from './components/ShareKeywordResearch.vue'
import NotFound from './components/NotFound.vue'
import { mdiCloseCircleOutline } from '@mdi/js'

export default {
  name: 'share',
  components: {
    ViewLoader,
    ShareSerpReport,
    ShareSemanticReport,
    ShareFullReport,
    ShareKeywordResearch,
    NotFound,
  },
  props: {},
  data() {
    return {
      loading: false,
      data: {},
      token: '',
      error: false,
    }
  },
  created() {
    this.getData()
    this.token = this.$route.params.token
  },
  methods: {
    ...mapActions({
      getShared: 'share/getShared',
    }),
    async getData() {
      this.loading = true
      await this.getShared(
        new URLSearchParams({
          ...this.$route.params,
        }).toString(),
      )
        .then(resp => {
          if (resp && resp.data) {
            this.data = resp.data
          }
        })
        .catch(err => {
          this.error = true
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="css">
.center {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.report {
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;
}
.not-found {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
</style>
