<template>
  <div>
    <h3></h3>
    <p>It seems that the report does not exist or is not available.</p>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
}
</script>
